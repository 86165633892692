import React from 'react';

import UI from '#front-credpago';

import * as S from './styles';

const MainContent = () => {
  return (
    <S.MainContainer>
      <S.MainContainerSection>
        <UI.Col>
          <UI.Title level={4} fontWeight={700}>
            O que é um cookie?
          </UI.Title>
          <div className="content">
            <p>
              Um cookie é um dado que o website solicita ao seu navegador para armazenar no seu computador ou
              dispositivo móvel. O cookie permite que o website “lembre” suas ações ou preferências ao longo do tempo.
            </p>
            <p>
              A maioria dos navegadores da Internet aceitam cookies; entretanto, os usuários podem configurar seus
              navegadores para recusar certos tipos de cookies ou cookies específicos. Além disso, os usuários podem
              apagar os cookies a qualquer momento.
            </p>
          </div>
        </UI.Col>
      </S.MainContainerSection>
      <S.MainContainerSection>
        <UI.Col>
          <UI.Title level={4} fontWeight={700}>
            Por que usamos cookies?
          </UI.Title>
          <div className="content">
            <p>
              Usamos cookies para aprender como você interage com o nosso conteúdo e para melhorar sua experiência ao
              visitar o nosso website. Por exemplo, alguns cookies lembram o seu idioma ou preferências para que você
              não tenha que efetuar estas escolhas repetidamente sempre que visitar um dos nossos websites. Além disso,
              os cookies permitem que ofereçamos um conteúdo específico, tais como vídeos no(s) nosso(s) website(s).
              Podemos empregar o que aprendemos sobre o seu comportamento no(s) nosso(s) website(s) para oferecer
              anúncios direcionados em website(s) de terceiros em um esforço para “reapresentar” nossos produtos e
              serviços para você.
            </p>
          </div>
        </UI.Col>
      </S.MainContainerSection>
      <S.MainContainerSection>
        <UI.Col>
          <UI.Title level={4} fontWeight={700}>
            Quais os tipos de Cookies que utilizamos?
          </UI.Title>
          <div className="content">
            <div className="subsection">
              <UI.Title level={5} fontWeight={700}>
                Cookies Primários e Cookies de Terceiros
              </UI.Title>
              <div className="subsection-content">
                <p>Utilizamos tanto cookies primários quanto cookies de terceiros no nosso website.</p>
                <p>
                  Cookies primários são cookies emitidos pelo domínio credpago.com.br e credpago.com e geralmente são
                  utilizados para identificar preferências de idioma e local ou facilitar a funcionalidade básica do
                  site. Cookies de terceiros pertencem e são administrados por outras empresas, tais com parceiros de
                  negócios da CredPago ou provedores de serviços.
                </p>
              </div>
            </div>
            <div className="subsection">
              <UI.Title level={5} fontWeight={700}>
                Cookies da Sessão
              </UI.Title>
              <div className="subsection-content">
                <p>
                  Cookies da sessão são cookies temporários que são utilizados para lembrar de você durante o curso da
                  sua visita ao website, e eles perdem a validade quando você fecha o navegador.
                </p>
              </div>
            </div>
            <div className="subsection">
              <UI.Title level={5} fontWeight={700}>
                Cookies Persistentes
              </UI.Title>
              <div className="subsection-content">
                <p>
                  Cookies persistentes são utilizados para lembrar suas preferências do website e permanecem no seu
                  desktop ou dispositivo móvel mesmo depois de você fechar o seu navegador ou efetuar uma
                  reinicialização. Usamos estes cookies para analisar o comportamento do usuário e estabelecer padrões,
                  de modo que possamos melhorar a funcionalidade do nosso website para você e outros que visitam o(s)
                  nosso(s) website(s). Estes cookies também permitem que possamos oferecer anúncios segmentados e medir
                  a eficácia do nosso site e a funcionalidade de tais anúncios.
                </p>
              </div>
            </div>
          </div>
        </UI.Col>
      </S.MainContainerSection>
      <S.MainContainerSection>
        <UI.Col>
          <UI.Title level={4} fontWeight={700}>
            Como os Cookies de terceiros são usados?
          </UI.Title>
          <div className="content">
            <p>
              Para algumas das funções dentro dos nossos websites usamos fornecedores terceirizados, por exemplo, quando
              você visita uma página com um vídeo incorporado ou proveniente do YouTube. Estes vídeos ou links (e
              qualquer outro conteúdo de fornecedores terceirizados) podem conter cookies de terceiros, e nós
              recomendamos consultar as políticas de privacidade destes terceiros nos seus websites para informações
              relativas a sua utilização de cookies.
            </p>
          </div>
        </UI.Col>
      </S.MainContainerSection>
      <S.MainContainerSection>
        <UI.Col>
          <UI.Title level={4} fontWeight={700}>
            Como remover os Cookies?
          </UI.Title>
          <div className="content">
            <p>Caso você queira remover os cookies utilizados pela CredPago de seu navegador, siga os passos abaixo:</p>
            <p>
              <ul>
                <li>Localize o menu de configurações/opções/preferências de seu navegador;</li>
                <li>Dentro da área de configurações, localize a seção de Segurança e Privacidade; </li>
                <li>Em Segurança e Privacidade, procure pela opção Limpar/Excluir Cookies; </li>
                <li>Após o procedimento, reinicie seu browser.</li>
              </ul>
            </p>
          </div>
        </UI.Col>
      </S.MainContainerSection>
    </S.MainContainer>
  );
};

export default MainContent;
