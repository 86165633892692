import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import MainContent from '../components/politicaCookies/main';
import PageHero from '../components/politicaCookies/pageHero';
import SEO from '../components/seo';

export const query = graphql`
  query {
    Cookies: file(relativePath: { eq: "cookies.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const PoliticaDeCookies = ({ data }) => {
  return (
    <Layout internal={true} hasChatButton={true}>
      <SEO title="Política de Cookies" />
      <PageHero data={data} />
      <MainContent />
    </Layout>
  );
};

export default PoliticaDeCookies;
