import React from 'react';

export default function useResizer() {
  const windowObjExists = typeof window !== 'undefined';
  const [isMobile, setIsMobile] = React.useState(windowObjExists && window.innerWidth < 575);

  React.useEffect(() => {
    function handleSizeChange() {
      return setIsMobile(windowObjExists && window.innerWidth < 575);
    }

    if (windowObjExists) {
      window.addEventListener('resize', handleSizeChange);
    }

    return () => {
      if (windowObjExists) {
        window.removeEventListener('resize', handleSizeChange);
      }
    };
  }, [isMobile, windowObjExists]);

  return isMobile;
}
