import { navigate } from 'gatsby';
import React from 'react';

import UI from '#front-credpago';

import { ReactComponent as SvgArrowDown } from '../../images/svg/arrow-down.svg';
import useResizer from '../../utils/useResizer';
import * as S from './styles';

const PageHero = ({ data }) => {
  const isMobile = useResizer();

  return (
    <S.FullWidthWrapper>
      <S.HeroContainer>
        <UI.Row>
          {isMobile && (
            <UI.Col responsive={{ xs: 12, sm: 12, md: 6 }}>
              <S.WrapperImg>
                <S.ImgHand fluid={data.Cookies.childImageSharp.fluid} />
              </S.WrapperImg>
            </UI.Col>
          )}
          <UI.Col responsive={{ xs: 12, sm: 12, md: 6 }}>
            <UI.Title fontWeight={700} level={1} splitLabel={['Política', 'de Cookies']} />
            <p>
              Nesta política usamos o termo “cookies” para nos referirmos aos cookies e outras tecnologias similares
              cobertas pela Diretiva da União Europeia sobre privacidade em comunicações eletrônicas.
            </p>
          </UI.Col>
          {!isMobile && (
            <UI.Col responsive={{ xs: 12, sm: 12, md: 6 }}>
              <S.WrapperImg>
                <S.ImgHand fluid={data.Cookies.childImageSharp.fluid} />
              </S.WrapperImg>
            </UI.Col>
          )}
        </UI.Row>
        {!isMobile && (
          <UI.Row id="conteudo">
            <UI.Col>
              <S.ButtonWrapper>
                <UI.Button icon={<SvgArrowDown />} onClick={() => navigate('#conteudo')} type="light" />
                <span>Role pra baixo</span>
              </S.ButtonWrapper>
            </UI.Col>
          </UI.Row>
        )}
      </S.HeroContainer>
    </S.FullWidthWrapper>
  );
};

export default PageHero;
