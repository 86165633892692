import Img from 'gatsby-image';
import styled from 'styled-components';

import UI from '#front-credpago';

import globals from '../globals';

const { containerMaxWidth, fonts, colors } = globals;

export const FullWidthWrapper = styled.section`
  display: flex;
  max-height: 100vh;
  height: 100%;
  position: relative;
`;

export const HeroContainer = styled.div`
  width: 100%;
  max-width: calc(${containerMaxWidth} + 40px);
  margin: 0 auto;
  margin-top: 53px;
  align-self: center;
  position: relative;

  h1 {
    display: flex;
    flex-direction: column;
  }

  p {
    color: ${colors.lightGray};
    font-family: ${fonts.sans};
    font-size: 15px;
    line-height: 25px;
  }

  div.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  font-family: ${fonts.default};

  & > span {
    align-self: center;
    margin-left: 20px;
  }
`;

export const WrapperImg = styled.div`
  position: relative;
`;

export const ImgHand = styled(Img)`
  position: absolute;
`;

export const MainContainer = styled.div`
  width: 100%;
  max-width: calc(${containerMaxWidth} + 40px);
  margin: 53px auto;
  align-self: center;
  position: relative;

  p {
    color: ${colors.lightGray};
    font-family: ${fonts.sans};
    font-size: 15px;
  }
`;

export const MainContainerSection = styled(UI.Row)`
  h4 {
    margin-bottom: 33px;
  }

  .content {
    p {
      font-family: ${globals.fonts.sans};
      line-height: 20px;
    }

    .subsection {
      margin-top: 33px;
      margin-left: 60px;

      h5 {
        color: #707070;
        margin-bottom: 33px;
      }
    }

    ul {
      list-style-type: '· ';
      list-style-position: outside;
    }
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    .content {
      .subsection {
        margin-left: 0;
      }
    }
  }
`;
